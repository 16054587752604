<template>
    <v-container fluid class="fill-height pa-0">
        <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="6">
                <v-col v-if="!pwValid" cols="12" sm="12" md="9" lg="10">
                    <v-form ref="form">
                        <v-card>
                            <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                                Neues Passwort vergeben
                            </v-card-title>
                            <v-card-text class="text-body-1 text--primary">
                                <!-- Password forms should have (optionally hidden) username fields for accessibility -->
                                <v-text-field label="IK/Benutzername" :value="loginName"
                                              autocomplete="username" v-show="false" readonly />

                                <v-text-field label="Neues Passwort" v-model="pwdNeu"
                                              autocomplete="new-password"
                                              :rules="passwordRules"
                                              :type="pwTextNew ? 'text' : 'password'"
                                              :append-icon="pwTextNew ? 'mdi-eye-off' : 'mdi-eye'"
                                              @click:append="() => (pwTextNew = !pwTextNew)"
                                              error-count="5"
                                              clearable />

                                <v-text-field label="Passwort bestätigen" v-model="pwdBestaetigen"
                                              autocomplete="new-password"
                                              :rules="ReapeatpasswordRules"
                                              :type="pwTextRp ? 'text' : 'password'"
                                              :append-icon="pwTextRp ? 'mdi-eye-off' : 'mdi-eye'"
                                              @click:append="() => (pwTextRp = !pwTextRp)"
                                              clearable />
                                <!--<verify-otp :guid="verCode" @verifyOtpDone="(rst) => { this.jwt = rst; }"
                                v-if="isUserValid && verCode" show-label />-->
                            </v-card-text>
                            <v-card-actions>
                                <v-btn :disabled="!isUserValid || waitingForCaptcha" color="primary"
                                       @click="closeRegisterNewUser" :loading="waitingForCaptcha">

                                    Weiter
                                </v-btn>
                                <v-btn color="primary" @click="doClear">
                                    Abbrechen
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-col>
                <v-col v-else-if="pwValid">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Authentifizierungs-App
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <p class="text-justify text-hyphenate">
                                Um die Datensicherheit zu gewährleisten, reicht ein Benutzername und Passwort für die Anmeldung im Service- Portal nicht aus. 
                                Sie benötigen einen zweiten Faktor, der in diesem Fall ein Mobilgerät mit einer installierten Authenticator-App darstellt. 
                                In dieser App wird ein sechsstelliger Zahlencode angezeigt, der sich alle 30 Sekunden ändert. 
                                Diesen Zahlencode benötigen Sie zusätzlich zu Ihrem Passwort für jede Anmeldung.
                            </p>

                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="font-weight-semibold">
                                        Ich habe bereits die Authentifizierungs-App installiert
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class="text-justify text-hyphenate">
                                            Wenn Sie die Authentifizierungs-App bereits vorliegen haben, dann geben Sie bitte den von der App erstellten Code ein:
                                     </p>
                                    <v-row>
                                        <v-col cols="12" sm="auto">
                                            <verify-otp :jwt="this.jwt" class="d-inline-flex" show-label
                                                        @verifyOtpDone="(rst) => { this.isOtpValid = true, this.jwt = rst; }" />
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto">
                                            <v-card-actions class="mt-2">
                                                        
                                                <v-btn color="primary" type="button"
                                                        @click.stop.prevent=" next=false">
                                                    Zurück
                                                </v-btn>
                                                <v-btn :disabled="!(this.isValid)" color="primary"
                                                        @click="doLogin()" :loading="loading">
                                                    Speichern
                                                </v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                     
                                        <!--<v-row class="justify-end mt-2" no-gutters>
                                            <v-col cols="auto">
                                                <v-card-actions>
                                                    <v-btn color="primary" type="button"
                                                           @click.stop.prevent=" next=false">
                                                        Zurück
                                                    </v-btn>
                                                    <v-btn :disabled="!(this.isValid)" color="primary"
                                                           @click="doLogin()" :loading="loading">
                                                        Speichern
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>-->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="font-weight-semibold">
                                        Ich möchte die Authentifizierungs-App neu installieren
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ol class="text-justify text-hyphenate pl-4">
                                            <li>
                                                <p>
                                                    Öffnen Sie ihre Authentifizierungs-App.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Fügen Sie in der App ein Konto hinzu und scannen Sie den unten stehenden QR-Code.
                                                </p>
                                                <v-row class="mb-3">
                                                    <v-col cols="12" sm="auto" class="text-center">
                                                        <qrcode-vue :value="otpauthLink" size="200" level="H" />
                                                    </v-col>
                                                    <v-col>
                                                        <p>
                                                            Sollten Sie den QR-Code nicht scannen können, dann geben Sie
                                                            in der App bitte folgenden Schlüssel ein:
                                                        </p>
                                                        <code class="d-inline-flex font-weight-bold align-center text-left pa-0">
                                                            <span class="ml-2 mr-1 py-1">
                                                                {{ secret && secret.replace(/(.{4})/g, '$1 ').trim() }}
                                                            </span>
                                                            <v-btn color="black" type="button"
                                                                   @click.stop.prevent="shareSecret2FA"
                                                                   icon tile text>

                                                                <v-icon>mdi-content-copy</v-icon>
                                                            </v-btn>
                                                            <a id="otpauthLinkOpener" :href="otpauthLink" class="d-none" />
                                                        </code>
                                                        <p v-show="copyClipboardSuccess" class="text-body-2 red--text">
                                                            Schlüssel in Zwischenablage kopiert
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </li>
                                            <li>
                                                <p>
                                                    Nachdem das Konto eingerichtet ist, geben Sie bitte den von der App erstellten Code ein:
                                                </p>
                                                <v-row>
                                                    <v-col cols="12" sm="auto">
                                                        <verify-otp :jwt="this.jwt" class="d-inline-flex" show-label
                                                                    @verifyOtpDone="(rst) => { this.isOtpValid = true, this.jwt = rst; }" />
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                    <v-col cols="auto">
                                                        <v-card-actions class="mt-2">

                                                            <v-btn color="primary" type="button"
                                                                   @click.stop.prevent=" next=false">
                                                                Zurück
                                                            </v-btn>
                                                            <v-btn :disabled="!(this.isValid)" color="primary"
                                                                   @click="doLogin()" :loading="loading">
                                                                Speichern
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-col>
                                                </v-row>
                                            </li>
                                        </ol>
                                        <!--<v-row class="justify-end mt-2" no-gutters>
                                            <v-col cols="auto">
                                                <v-card-actions>
                                                    <v-btn color="primary" type="button"
                                                           @click.stop.prevent=" next=false">
                                                        Zurück
                                                    </v-btn>
                                                    <v-btn :disabled="!(this.isValid)" color="primary"
                                                           @click="doLogin()" :loading="loading">
                                                        Speichern
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>-->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>

        <!-- Dialog Fehler -->
        <v-dialog v-model="showDialogFailure">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.error</v-icon>
                    Aktualisierung fehlgeschlagen
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-alert class="mt-4 mb-0" type="error">
                        {{ errorMessage }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="button" @click="showDialogFailure = false;">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />
    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue';
    import VerifyOtp from '@/components/VerifyOtp.vue';
    import eventBus from '@/main';
    import QrcodeVue from 'qrcode.vue'

    export default {
        name: 'BenutzerAnmeldung',

        components: {
            CaptchaFailDialog,
            VerifyOtp,
            QrcodeVue,
        },

        data() {
            return {
                verCode: null,
                loginName: null,
                leistungserbringer: null,
                secret: null,
                password: null,
                errorMessage: null,
                showPasswort: false,
                isOtpValid: false,
                loading: false,
                showDialogFailure: false,
                showDialogCaptchaFailure: false,
                copyClipboardSuccess: false,
                otpauthSchemeHandlerDetected: false,
                showPasswort: false,
                pwdNeu: '',
                pwdBestaetigen: '',
                pwTextNew: false,
                pwTextRp: false,
                emailRules: [
                    v => !!v || 'Bitte geben Sie eine gültige Email-Adresse ein',
                    v => /.+@.+/.test(v) || 'Geben Sie bitte eine gültige Email-Adresse ein'
                ],
                passwordRules: [
                    v => !!v || 'Bitte geben Sie Ihr gewünschtes Passwort ein',
                    v => (v && v.length >= 10) || 'Das Passwort muss mindestens 10 Zeichen lang sein',
                    v => /(?=.*[A-Z])/.test(v) || 'Das Passwort muss mindestens einen Großbuchstaben beinhalten',
                    v => /(?=.*[a-z])/.test(v) || 'Das Passwort muss mindestens einen Kleinbuchstaben beinhalten',
                    v => /(?=.*\d)/.test(v) || 'Das Passwort muss mindestens eine Zahl beinhalten',
                    v => /([!@$%"&/\\()+=?#,.;*_-])/.test(v) || 'Das Passwort muss mindestens ein Sonderzeichen [!@$%"&/\\()+=?#,.;*_-] beinhalten'
                ],
                ReapeatpasswordRules: [
                    v => !!v || 'Bitte wiederholen Sie hier Ihr Passwort',
                    v => v == this.pwdNeu || 'Die beiden Passwörter müssen übereinstimmen'
                ],
                showDialogSuccess: false,
                showDialogFailure: false,
                active: false,
                waitingForCaptcha: false,
                verCode: '',
                verificationSuccess: false,
                jwt: null,
                next: false,
                Benutzer: {}
            }
        },
  
        computed: {
            otpauthLink: function () {

                let label = this.leistungserbringer;
                let secret = this.secret;
                let issuer = 'Service-Portal%20f%C3%BCr%20Apotheken';

                //z.B. Service-Portal für Apotheken (IK 123456789)
                return `otpauth://totp/IK%20${label}?secret=${secret}&issuer=${issuer}`;
            },

            isValid() {
                return this.verificationSuccess && this.isOtpValid;
            },

            isUserValid() {
                 return this.pwdNeu && this.pwdNeu === this.pwdBestaetigen && !this.next;
            },

            pwValid(){
                //console.log( "this.pwdNeu: ", this.pwdNeu,  "this.pwdBestaetigen: ",this.pwdBestaetigen, "this.next: ",this.next,"this.isUserValid: ",this.isUserValid );
                return  this.pwdNeu && this.pwdNeu === this.pwdBestaetigen && this.next;
            }
        },
 
        activated() {
            this.$nextTick(async () => {
                this.$refs.form.reset();
                this.verCode = this.$route.query.vercode;
             });
        },

        methods: {
            async closeRegisterNewUser() {
                this.waitingForCaptcha = true;

                // save the new password and login in the App
                this.$http.post('auth/completeregisternewuser', {
                        RegCode: this.verCode,
                        Password: this.pwdNeu,

                    }).then((response) => {
                        if (response.status === 200) {
                            this.waitingForCaptcha = false;
                            this.verificationSuccess = true;
                            this.next = true;
                            this.secret = response.data.secret2FA;
                            this.jwt = response.data.jwtToken;
                            this.leistungserbringer = response.data.leistungserbringer;
                        }
                    }).catch(error => {
                        if (error.response) {
                            //console.log(error.response.data.message); 
                            this.errorMessage = error.response.data.message;
                            this.showDialogFailure = true;
                            this.waitingForCaptcha = false;
                        }
                    });
            },

            async doLogin() {
                try {
                    this.loading = true;
                    let response = await this.$http.post('auth/login', {
                        Jwt: this.jwt,
                        CookieConsent: this.$store.state.cookieConsent,
                    });

                    if (response.status === 200) {
                        this.$store.commit('updateCurrentUser', response.data);

                        //Module aufrufen
                        await this.getModule();

                        this.loginDone();
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.errorMessage = error.response.data.message;
                        this.showDialogFailure = true;
                    }
                }
                finally {
                    this.loading = false;
                }
            },

            loginDone() {
                eventBus.$emit('loginSuccessful');
                eventBus.$emit('resetLogoutTimer');
                this.errorMessage = "";
                this.showDialogFailure = false;
                this.$router.push('/');
            },

            convertDatum(datum) {
                //Zeit aus Datum entfernen
                let dt = datum;
                if (datum) {
                    dt = new Date(datum);
                }
                else {
                    dt = new Date();
                }
                dt.setHours(0, 0, 0, 0);
                return Date.parse(dt);
            },

            findUserRight(i) {
                //Benutzerrecht finden
                let ix = this.$store.state.currentUser.rechte.findIndex(r => r.recht === i);
                return ix !== -1
            },

            async getModule() {
                try {

                    let response = await this.$http.get('module');
                    if (response.status === 200) {
                        var result = response.data;
                        let module = {
                            retaxRead: false,
                            retaxWrite: false,
                            zuzahlungRead: false,
                            zzRueckforderungRead: false,
                            zzRueckforderungWrite: false
                        };

                        let dateNow = this.convertDatum("");
                        let dateOpen = Date.parse("12/31/9999");

                        result.forEach(r => {
                            let gbDate = this.convertDatum(r.gbDatum);

                            switch (r.modul) {
                                case 1:
                                    //let modulrights = this.$store.state.currentUser.rechte.findIndex(r => r.recht === 1 || 2 )
                                    if (gbDate === dateOpen) {
                                        module.zuzahlungRead = true && this.findUserRight(3);
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                        module.zzRueckforderungWrite = true && this.findUserRight(8);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                    break;
                                case 2:
                                    if (gbDate === dateOpen) {
                                        module.retaxRead = true && this.findUserRight(1);
                                        module.retaxWrite = true && this.findUserRight(2);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.retaxRead = true && this.findUserRight(1);
                                    break;
                            }
                        });
                        //console.log('module: ', module);

                        this.$store.commit('updateModule', {
                            retaxRead: module.retaxRead,
                            retaxWrite: module.retaxWrite,
                            zuzahlungRead: module.zuzahlungRead,
                            zzRueckforderungRead: module.zzRueckforderungRead,
                            zzRueckforderungWrite: module.zzRueckforderungWrite
                        });
                        //console.log('this.$store.state.module', this.$store.state.module)
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                }
            },

            async shareSecret2FA() {
                let timeout = 1000;
                let ctx = this;

                let copyToClipboard = function () {
                    navigator.clipboard.writeText(ctx.secret);
                    ctx.copyClipboardSuccess = true;
                };

                let onBlur = function () {
                    ctx.otpauthSchemeHandlerDetected = true;
                };

                let onVisibilityChange = function (e) {
                    if (e.target.visibilityState === 'hidden') {
                        ctx.otpauthSchemeHandlerDetected = true;
                    }
                };

                if (!ctx.otpauthSchemeHandlerDetected) {

                    window.addEventListener('blur', onBlur);
                    document.addEventListener('visibilitychange', onVisibilityChange);

                    setTimeout(function () {

                        if (!ctx.otpauthSchemeHandlerDetected) {
                            copyToClipboard();
                        }

                        window.removeEventListener('blur', onBlur);
                        document.removeEventListener('visibilitychange', onVisibilityChange);

                    }, timeout);
                }

                ctx.copyClipboardSuccess = false;
                document.getElementById('otpauthLinkOpener').click();
            },

            clear() {
                this.$refs.form.reset();
                this.$store.commit('updateCurrentUser', null);
                this.$router.push('/login');
            },
 
            doClear() {
                //clear the textboxes and go the the login window
                this.showDialog = false;

                this.pwdNeu = null;
                this.pwdBestaetigen = null;

                this.showPasswort = false;
                this.pwTextRp = false;
                this.pwTextNew = false;

                this.$refs.form.reset();
                this.$store.commit('updateCurrentUser', null);
                this.$router.push('/');
            }
        }
    }
</script>