import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 text-md-h4 flex-nowrap align-start"},[_c(VIcon,{attrs:{"color":"primary","large":"","left":""}},[_vm._v("$vuetify.icons.clockAlert")]),_vm._v(" Überprüfung fehlgeschlagen ")],1),_c(VCardText,{staticClass:"text-body-1 text--primary"},[_vm._v(" Die Anfrage war nicht erfolgreich. Bevor Sie es erneut versuchen können, müssen Sie zwei Minuten warten. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","type":"button","disabled":_vm.timerCount > 0},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(" "+_vm._s(_vm.btnWaitText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }