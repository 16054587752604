<template>
    <v-dialog v-model="dialogVisible" persistent>
        <v-card>
            <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                <v-icon color="primary" large left>$vuetify.icons.clockAlert</v-icon>
                Überprüfung fehlgeschlagen
            </v-card-title>
            <v-card-text class="text-body-1 text--primary">
                Die Anfrage war nicht erfolgreich. Bevor Sie es erneut versuchen können, müssen Sie zwei Minuten warten.
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" type="button" :disabled="timerCount > 0"
                       @click="dialogVisible = false">

                    {{ btnWaitText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'CaptchaFailDialog',

        props: {
            isVisible: Boolean
        },

        data() {
            return {
                timerCount: 0,
                dialogVisible: false,
            }
        },

        computed: {
            btnWaitText() {
                return (this.timerCount > 0) ? this.timerCount : 'Schließen';
            }
        },

        watch: {
            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                }
            },

            isVisible: function (b) {
                this.dialogVisible = b;
                if (b)
                    this.timerCount = 120;  // set timer to 120 sec when dialog becomes visible
            }
        }
    }
</script>
